<template>
    <div>
        <app-layout>
	        <template v-slot:header>
                <Header :title="$t('virtual_pos')"
                        :isNewButton="checkPermission('virtualpos_store')"
                        @new-button-click="add">
                </Header>
	        </template>
	        <template v-slot:header-mobile>
		        <HeaderMobile :title="$t('virtual_pos')"
		                      :isNewButton="checkPermission('virtualpos_store')"
		                      @new-button-click="add">
                </HeaderMobile>
	        </template>
            <datatable :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       :lineNumbers="false"
                       @on-page-change="onPageChange"
                       @on-sort-change="onSortChange"
                       @on-per-page-change="onPerPageChange"
                       v-show="datatable.showTable"
            >
            </datatable>
            <CommonModal ref="modal" size="md">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t(id==0?'add':'edit') }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <div class="row mb-5 align-items-center">
                        <div class="col-12">
                            <ValidationObserver ref="storeForm">
                                <div class="row">
                                    <div class="col-6 col-xs-12">
                                        <ValidationProvider name="code" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('code')">
                                                <b-form-input
                                                    :validate-error="errors[0]"
                                                    v-model="form.code">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12">
                                        <ValidationProvider name="name" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('name')">
                                                <b-form-input
                                                    :validate-error="errors[0]"
                                                    v-model="form.name">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                    <div class="col-12">
                                        <ValidationProvider name="name_en" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('name_en')">
                                                <b-form-input
                                                    :validate-error="errors[0]"
                                                    v-model="form.name_en">
                                                </b-form-input>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-6 col-xs-12">
                                        <ValidationProvider name="installments" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('installments')">
                                                <multi-selectbox v-model="form.installments"
                                                                 :options="installmentOptions"
                                                :validateError="errors[0]"></multi-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-6 col-xs-12">
                                        <ValidationProvider name="extra_installment" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('extra_installment')">
                                                <multi-selectbox v-model="form.extra_installment"
                                                                 :options="extraInstallmentOptions"
                                                                 :validateError="errors[0]" :multiple="false"></multi-selectbox>

                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12">
                                        <ValidationProvider name="bank_id" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('bank')">
                                                <bank-selectbox
                                                    :validate-error="errors[0]"
                                                    v-model="form.bank_id">
                                                </bank-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12">
                                        <ValidationProvider name="cash_payment_type_id" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('cash_payment_type')">
                                                <payment-types-selectbox
                                                    :is_bank_required="true"
                                                    type="online_credit_card"
                                                    :bank_id="form.bank_id"
                                                    :validate-error="errors[0]"
                                                    v-model="form.cash_payment_type_id">
                                                </payment-types-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-12">
                                        <ValidationProvider name="installment_payment_type_id" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('installment_payment_type')">
                                                <payment-types-selectbox
                                                    :is_bank_required="true"
                                                    type="online_credit_card"
                                                    :bank_id="form.bank_id"
                                                    :validate-error="errors[0]"
                                                    v-model="form.installment_payment_type_id">
                                                </payment-types-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-6 col-xs-12">
                                        <ValidationProvider name="is_default" rules="" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('is_default')">
                                                <b-form-checkbox
                                                    value="1"
                                                    v-model="form.is_default">
                                                </b-form-checkbox>
                                                <b-form-invalid-feedback v-if="errors[0]"
                                                                         v-html="errors[0]"></b-form-invalid-feedback>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>

                                    <div class="col-6 col-xs-12">
                                        <ValidationProvider name="status" rules="required" v-slot="{valid, errors}">
                                            <b-form-group :label="$t('status')">
                                                <status-selectbox
                                                    :validate-error="errors[0]"
                                                    v-model="form.status">
                                                </status-selectbox>
                                            </b-form-group>
                                        </ValidationProvider>
                                    </div>
                                </div>
                            </ValidationObserver>
                        </div>
                        <div class="col-12 mt-3 d-flex">
                            <b-button @click="store"
                                      type="button"
                                      variant="primary"
                                      class="btn-lg mr-2">
                                {{ $t('save') | toUpperCase }}
                            </b-button>
                        </div>
                    </div>
                </template>
            </CommonModal>
        </app-layout>
    </div>
</template>
<script>
    import AppLayout from "@/layouts/AppLayout"
    import Header from "@/layouts/AppLayout/Header"
    import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
    import Datatable from "@/components/datatable/Datatable";
    import qs from 'qs'
    import CommonModal from "@/components/elements/CommonModal";
    import {ValidationObserver, ValidationProvider} from "vee-validate";
    import VirtualPosService from "@/services/VirtualPosService";
    import BankSelectbox from "@/components/interactive-fields/BankSelectbox";
    import PaymentTypesSelectbox from "@/components/interactive-fields/PaymentTypesSelectbox";
    import StatusSelectbox from "@/components/interactive-fields/StatusSelectbox";
    import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";

    export default {
        components: {
            StatusSelectbox,
            PaymentTypesSelectbox,
            BankSelectbox,
            AppLayout,
            Header,
            Datatable,
            CommonModal,
            MultiSelectbox,
            HeaderMobile,
            ValidationProvider,
            ValidationObserver
        },
        metaInfo() {
            return {
                title: this.$t('virtual_pos')
            }
        },
        data() {
            return {
                id:0,
                form: {},
                installmentOptions:[
                    {value:'2', text:'2'},
                    {value:'3', text:'3'},
                    {value:'4', text:'4'},
                    {value:'5', text:'5'},
                    {value:'6', text:'6'},
                    {value:'7', text:'7'},
                    {value:'8', text:'8'},
                    {value:'9', text:'9'},
                    {value:'10', text:'10'},
                    {value:'11', text:'11'},
                    {value:'12', text:'12'},
                ],
                extraInstallmentOptions:[
                    {value:'0', text:'0'},
                    {value:'1', text:'1'},
                    {value:'2', text:'2'},
                    {value:'3', text:'3'},
                    {value:'4', text:'4'},
                    {value:'5', text:'5'},
                    {value:'6', text:'6'},
                ],
                datatable: {
                    isLoading: false,
                    columns: [
                        {
                            label: '',
                            field: 'buttons',
                            html: true,
                            sortable: false,
                            tdClass: 'p-0 text-center w-40 align-middle',
                            buttons: [
                                {
                                    text: this.$t('edit'),
                                    class: 'ri-edit-box-line',
                                    permission: "virtualpos_update",
                                    callback: (row) => {
                                        this.id = row.id;
                                        this.loadData();
                                    }
                                }
                            ]
                        },
                        {
                            label: this.$t('name'),
                            field: this.getLocaleField('name'),
                            sortable: true,
                        },
                        {
                            label: this.$t('status'),
                            field: 'status',
                            sortable: true,
                            formatFn: this.getStatusText
                        },
                    ],
                    rows: [],
                    total: 0,
                    filterStatus: false,
                    showTable: true,
                    queryParams: {
                        filter: {
                        },
                        sort: 'id',
                        page: 1,
                        limit: 20
                    }
                }
            }
        },
        created() {
            this.getRows()
        },
        methods: {
            onPageChange(params) {
                this.datatable.queryParams.page = params.currentPage;
                this.getRows();
            },
            onPerPageChange(params) {
                this.datatable.queryParams.limit = params.currentPerPage;
                this.datatable.queryParams.page = 1;
                this.getRows();
            },
            onSortChange(params) {
                const sortType = params[0].type == 'desc' ? '-' : '';
                this.datatable.queryParams.sort = sortType + params[0].field
                this.getRows();
            },
            getRows() {
                this.datatable.showTable = true;
                this.datatable.isLoading = true;

                const config = {
                    params: {
                        ...this.datatable.queryParams
                    },
                    paramsSerializer: (params) => qs.stringify(params, {encode: false})
                };

                return VirtualPosService.getAll(config)
                                          .then((response) => {
                                              this.datatable.rows = response.data.data
                                              this.datatable.total = response.data.pagination.total
                                          })
                                          .finally(() => {
                                              this.datatable.isLoading = false;
                                          });
            },
            loadData() {
                if (this.id > 0) {
                    VirtualPosService.get(this.id)
                        .then(response => {
                            this.$refs.storeForm.reset();
                            this.form = response.data.data;
                            if(this.form.is_default){
                                this.form.is_default=1;
                            }
                            this.$refs.modal.$refs.commonModal.show();
                        }).catch(error => {
                        if (error.data.message) {
                            this.$toast.error(this.$t('api.' + error.data.message));
                        }
                    });
                }
            },
            async store() {
                const isValid = await this.$refs.storeForm.validate();
                if (isValid) {
                    if(!this.form.is_default){
                        this.form.is_default=0;
                    }
                    VirtualPosService.store(this.form)
                        .then(response => {
                            this.$toast.success(this.$t('api.' + response.data.message));
                        })
                        .catch(e => {
                            if (e.data.message) {
                                this.$toast.error(this.$t('api.' + e.data.message));
                            }
                        });
                }
            },

            add(){
                this.id=0;
                this.$refs.storeForm.reset();
                this.form={};
                this.$refs.modal.$refs.commonModal.show();
            }
        }
    };
</script>

